<template>
    <div>
        <page-toolbar title="Template">
            <template #contents>
                <v-spacer />

                <div style="width:400px">
                    <v-select
                        outlined
                        dense
                        :hide-details="!templateCreated"
                        messages="Page refresh may be required for rendering the new templates"
                        v-model="templateName"
                        :items="templateNamesWithAux"
                        @change="onTemplateSelect"
                        label="Template name"
                    >
                        <template v-if="templateCreated" v-slot:message="{ message }">
                            <span style="color:darkorange;font-weight:bold;">{{ message }}</span>
                        </template>
                    </v-select>
                </div>

                <v-spacer />

                <v-btn
                    text
                    color="indigo"
                    :disabled="!projectName"
                    to="/console/template/create"
                >
                    <v-icon left>mdi-plus-box-multiple-outline</v-icon>
                    Create template
                </v-btn>

                <v-btn
                    text
                    color="indigo"
                    :disabled="!templateName"
                    @click.stop="openTemplateEditorWindow"
                >
                    <v-icon left>mdi-file-code-outline</v-icon>
                    Open File Editor
                </v-btn>
            </template>
        </page-toolbar>

        <dialog-create-template
            ref="dialogCreateTemplate"
            :client="client"
            :project-name="projectName"
            :presets="presets"
            @complete="onCreationComplete"
        />

    </div>
</template>

<script>
import PageToolbar from '@/components/ui/PageToolbar'
import DialogCreateTemplate from './DialogCreateTemplate'

export default {
    components: {
        PageToolbar,
        DialogCreateTemplate
    },
    data: () => ({
        templateName: null,
        templateCreated: false,
    }),
    props: ['client', 'projectName', 'templateNames', 'presets'],
    computed: {
        templateNamesWithAux() {
            return [...this.templateNames, "[Instruction]", "[Preview]"];
        },
    },
    methods: {
        onTemplateSelect() {
            this.currentAnswer = {};
            this.$emit('template-change', this.templateName);
        },
        onCreationComplete() {
            this.templateCreated = true;
        },
        async openTemplateEditorWindow() {
            window.open(`/code-editor/template?project_name=${this.projectName}&template_name=${this.templateName}`, 'templateEditorWindow', ';');
        },
    },
    watch: {
        templateNames() { this.templateName = null; }
    }
}
</script>
