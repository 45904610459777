<template>
    <v-main>
        <template-select-toolbar
            :client="client"
            :projectName="projectName"
            :templateNames="templateNames"
            :presets="presets"
            @template-change="loadTemplate"
        />

        <v-row style="height:calc(100% - 53px)">
            <v-col cols="12">
                <v-fade-transition hide-on-leave>
                    <component
                        v-show="tmplComponent"
                        :is="tmplComponent"
                        @submit="showSubmitResults"
                        @set-nano-props="updateNanoProps"
                        @response-update="updateNanoAnswers"
                    ></component>
                </v-fade-transition>
                <v-fade-transition hide-on-leave>
                    <iframe
                        v-show="tmplHTML"
                        :srcdoc="tmplHTML"
                        ref="iframe"
                        style="width:100%;height:100%;border:0;"
                    />
                </v-fade-transition>
            </v-col>
        </v-row>
        
        <response-navigation-drawer
            v-if="tmplComponent"
            :nano-props="nanoProps"
            :nano-answers="nanoAnswers"
        ></response-navigation-drawer>

        <dialog-submit-response
            ref="dialogSubmitResponse"
            :nanoAnswers="sentNanoAnswers"
        ></dialog-submit-response>
    </v-main>
</template>

<script>
import TemplateSelectToolbar from './TemplateSelectToolbar'
import ResponseCard from './ResponseCard'
import ResponseNavigationDrawer from './ResponseNavigationDrawer'
import DialogSubmitResponse from './DialogSubmitResponse.vue'
import loadTemplate from '@/lib/loadTemplate.js'

export default {
    components: {
        TemplateSelectToolbar,
        ResponseCard,
        ResponseNavigationDrawer,
        DialogSubmitResponse,
    },
    data: () => ({
        templateNames: [],
        presets: [],

        nanoProps: {},
        nanoAnswers: {},
        sentNanoAnswers: {},
        tmplComponent: null,
        tmplHTML: null,

    }),
    props: ['client', 'projectName'],
    methods: {
        updateNanoProps($event) {
            this.nanoProps = $event;
        },
        updateNanoAnswers($event) {
            this.nanoAnswers = $event;
        },
        showSubmitResults($event) {
            this.sentNanoAnswers = $event;
            this.$refs.dialogSubmitResponse.show();
        },
        resetTemplate() {
            this.tmplComponent = null;
            this.tmplHTML = null;
        },
        async listTemplates() {
            this.resetTemplate();
            if(this.projectName) {
                this.templateNames = await this.client.resource.listTemplates({ project_name: this.projectName });
                this.presets = await this.client.resource.listTemplatePresets({ project_name: this.projectName });
                console.log(this.presets);
            }
        },
        async loadTemplate(templateName) {
            this.resetTemplate();

            if(this.projectName && templateName) {
                const { tmplComponent, tmplHTML, requirePath } = loadTemplate(this.projectName, templateName);

                if (tmplComponent) {
                    this.tmplComponent = tmplComponent;

                } else if (tmplHTML) {
                    const module = require(`@/lib/nano.js`).default;
                    let iframe = this.$refs.iframe;
                    iframe.addEventListener('load', () => {
                        iframe.contentWindow.nano = module;
                        iframe.contentWindow.dispatchEvent(new CustomEvent('nanoLoad'));
                    });
                    this.tmplHTML = tmplHTML;

                } else {
                    alert(`no template was found at ${requirePath}`);
                }
            }
        },
    },
    watch: {
        projectName(name) { if(name) this.listTemplates(); },
    },
    mounted() {
        window.addEventListener('message', (e) => {
            if(e.data.tutti){
                switch (e.data.tutti.event){
                    case 'submit':
                        this.showSubmitResults(e.data.tutti.data || this.response);
                        break;
                }
            }
        });
        this.client.invokeOnOpen(() => {
            if(this.projectName) this.listTemplates();
        });
    }
}
</script>
<style scoped>
#toolbar {
    border-bottom: thin;
}
</style>
